import {
  isPublicContext,
  isServerContext,
} from '@squareup/dex-utils-environment';

// DataDog defines environments as prod, pre-prod, staging, etc.
const dataDogEnvironmentMap = new Map([
  ['production', 'prod'],
  ['staging', 'staging'],
]);

interface DatadogRUMOptions {
  applicationId: string;
  clientToken: string;
  service: string;
  env: string;
  version: string;
}

function importDatadogRum() {
  return import('@datadog/browser-rum');
}

let rumInstance: Awaited<ReturnType<typeof importDatadogRum>>['datadogRum'];
async function initializeDatadogRUM(
  rumOptions: DatadogRUMOptions
): Promise<void> {
  if (!isValidConfig(rumOptions) || rumInstance) {
    return;
  }

  const { datadogRum } = await importDatadogRum();
  datadogRum.init({
    applicationId: rumOptions.applicationId,
    clientToken: rumOptions.clientToken,
    site: 'datadoghq.com',
    service: rumOptions.service,
    env: dataDogEnvironmentMap.get(rumOptions.env),
    version: rumOptions.version,
  });

  // eslint-disable-next-line require-atomic-updates
  rumInstance = datadogRum;
}

function isValidConfig(rumOptions: DatadogRUMOptions) {
  return (
    !isServerContext() &&
    isPublicContext() &&
    dataDogEnvironmentMap.has(rumOptions.env) &&
    rumOptions.applicationId &&
    rumOptions.clientToken
  );
}

export { initializeDatadogRUM };
