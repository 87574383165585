import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import {
  CreateAddOnRequest,
  CreateAddonResponse,
  DeleteAddonVersionRequest,
  DeleteAddonVersionResponse,
  CreateAddonSecretRequest,
  CreateAddonSecretResponse,
  DeleteAddonSecretRequest,
  DeleteAddonSecretResponse,
  DeleteSellerAddonIntegrationsRequest,
  DeleteSellerAddonIntegrationsResponse,
  ListAddOnsRequest,
  ListAddOnsResponse,
  ListAddonSecretsRequest,
  ListAddonSecretsResponse,
  ListAddonVersionsRequest,
  ListAddonVersionsResponse,
  ListSellerAddonIntegrationsRequest,
  ListSellerAddonIntegrationsResponse,
  UpsertSellerAddonIntegrationsRequest,
  UpsertSellerAddonIntegrationsResponse,
} from '@squareup/dex-types-shared-add-ons';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';

import { ADD_ONS_API_REDUCER_PATH } from './add-ons-constants';
import { AddOnsApiTagTypes } from './add-ons-tag-types';

const ADD_ONS_DEVELOPER_URL = '/v2/dashboard-add-ons/addons';
const ADD_ONS_SELLER_URL = '/v2/sdao/seller-add-on-integrations';
const appName = 'sdao-manager';

const addOnsEndpointFactory = (
  builder: BaseEndpointBuilder<typeof ADD_ONS_API_REDUCER_PATH>
) => ({
  listAddons: builder.query<ListAddOnsResponse, ListAddOnsRequest>({
    query: ({ client_id }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}?client_id=${client_id}`;

      return {
        appName,
        method: HTTP_METHOD.GET,
        path,
      };
    },
    providesTags: (response) => {
      const tags =
        response?.addons?.map(({ id }) => ({
          type: AddOnsApiTagTypes.AddOn,
          id,
        })) ?? [];

      return [...tags, { type: AddOnsApiTagTypes.AddOn, id: 'LIST' }];
    },
  }),
  createAddon: builder.mutation<CreateAddonResponse, CreateAddOnRequest>({
    query: ({ client_id, idempotency_key }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}`;

      return {
        appName,
        method: HTTP_METHOD.POST,
        path,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id,
          idempotency_key,
        }),
      };
    },
    invalidatesTags: (response, _error, _params) =>
      response
        ? [
            {
              type: AddOnsApiTagTypes.AddOn,
              id: 'LIST',
            },
          ]
        : [],
  }),
  listAddonSecrets: builder.query<
    ListAddonSecretsResponse,
    ListAddonSecretsRequest
  >({
    query: ({ addon_id }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}/${addon_id}/secrets`;

      return {
        appName,
        method: HTTP_METHOD.GET,
        path,
      };
    },
    providesTags: (response) => {
      const secretTags =
        response?.addon_secrets?.map(({ id }) => ({
          type: AddOnsApiTagTypes.AddOnSecret,
          id,
        })) ?? [];

      return [
        ...secretTags,
        { type: AddOnsApiTagTypes.AddOnSecret, id: 'LIST' },
      ];
    },
  }),
  createAddonSecret: builder.mutation<
    CreateAddonSecretResponse,
    CreateAddonSecretRequest
  >({
    query: ({ addon_id, idempotency_key }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}/${addon_id}/secrets`;

      return {
        appName,
        method: HTTP_METHOD.POST,
        path,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idempotency_key,
        }),
      };
    },
    invalidatesTags: (response, _error, _params) =>
      response
        ? [
            {
              type: AddOnsApiTagTypes.AddOnSecret,
              id: 'LIST',
            },
          ]
        : [],
  }),
  deleteAddonSecret: builder.mutation<
    DeleteAddonSecretResponse,
    DeleteAddonSecretRequest
  >({
    query: ({ addon_id, id }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}/${addon_id}/secrets/${id}`;

      return {
        appName,
        method: HTTP_METHOD.DELETE,
        path,
      };
    },
    invalidatesTags: (response, _error, _params) =>
      response
        ? [
            {
              type: AddOnsApiTagTypes.AddOnSecret,
              id: 'LIST',
            },
          ]
        : [],
  }),
  listAddonVersions: builder.query<
    ListAddonVersionsResponse,
    ListAddonVersionsRequest
  >({
    query: ({ addon_id }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}/${addon_id}/versions`;

      return {
        appName,
        method: HTTP_METHOD.GET,
        path,
      };
    },
    providesTags: (response) => {
      const tags =
        response?.addon_versions?.map(({ id }) => ({
          type: AddOnsApiTagTypes.AddOnVersions,
          id,
        })) ?? [];

      return [...tags, { type: AddOnsApiTagTypes.AddOnVersions, id: 'LIST' }];
    },
  }),
  listSellerAddonIntegrations: builder.query<
    ListSellerAddonIntegrationsResponse,
    ListSellerAddonIntegrationsRequest
  >({
    query: () => {
      const path = `${ADD_ONS_SELLER_URL}`;

      return {
        appName,
        method: HTTP_METHOD.GET,
        path,
      };
    },
    providesTags: (response) => {
      const tags =
        response?.seller_addon_integrations?.map(({ id }) => ({
          type: AddOnsApiTagTypes.SellerAddonIntegration,
          id,
        })) ?? [];

      return [
        ...tags,
        { type: AddOnsApiTagTypes.SellerAddonIntegration, id: 'LIST' },
      ];
    },
  }),
  upsertSellerAddonIntegration: builder.mutation<
    UpsertSellerAddonIntegrationsResponse,
    UpsertSellerAddonIntegrationsRequest
  >({
    query: ({ addon_version_id, idempotency_key }) => {
      const path = `${ADD_ONS_SELLER_URL}`;

      return {
        appName,
        method: HTTP_METHOD.POST,
        path,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addon_version_id,
          idempotency_key,
        }),
      };
    },
    invalidatesTags: (response, _error, _params) =>
      response
        ? [
            {
              type: AddOnsApiTagTypes.SellerAddonIntegration,
              id: 'LIST',
            },
          ]
        : [],
  }),
  deleteSellerAddonIntegration: builder.mutation<
    DeleteSellerAddonIntegrationsResponse,
    DeleteSellerAddonIntegrationsRequest
  >({
    query: ({ id }) => {
      const path = `${ADD_ONS_SELLER_URL}/${id}`;

      return {
        appName,
        method: HTTP_METHOD.DELETE,
        path,
      };
    },
    invalidatesTags: (response, _error, _params) =>
      response
        ? [
            {
              type: AddOnsApiTagTypes.SellerAddonIntegration,
              id: 'LIST',
            },
          ]
        : [],
  }),
  deleteAddonVersion: builder.mutation<
    DeleteAddonVersionResponse,
    DeleteAddonVersionRequest
  >({
    query: ({ addon_id, addon_version_id }) => {
      const path = `${ADD_ONS_DEVELOPER_URL}/${addon_id}/versions/${addon_version_id}`;

      return {
        appName,
        method: HTTP_METHOD.DELETE,
        path,
      };
    },
    invalidatesTags: (response, _error, _params) =>
      response
        ? [
            {
              type: AddOnsApiTagTypes.AddOnVersions,
              id: 'LIST',
            },
          ]
        : [],
  }),
});

const addOnsEndpoints = addOnsEndpointFactory(endpointBuilder);

export { addOnsEndpoints, addOnsEndpointFactory };
