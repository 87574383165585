import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import {
  AcceptAppSubmissionPartnerTosRequest,
  AcceptAppSubmissionPartnerTosResponse,
  ChangeStatusQaAppSubmissionRequest,
  CreateAppSubmissionRequest,
  CreateAppSubmissionResponse,
  GetAppSubmissionPartnerTosResponse,
  GetQaAppSubmissionFeedbackRequest,
  GetQaAppSubmissionFeedbackResponse,
  GetQaAppSubmissionRequest,
  GetQaAppSubmissionResponse,
  SubmissionsMetadataRequest,
  SubmissionsMetadataResponse,
  SubmitQaAppSubmissionRequest,
  SubmitQaAppSubmissionResponse,
  UpdateQaAppSubmissionFeedbackRequest,
  UpdateQaAppSubmissionFeedbackResponse,
  UpdateQaAppSubmissionRequest,
  UpdateQaAppSubmissionResponse,
} from '@squareup/dex-types-shared-app-submission';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';
import { DeveloperApiTagTypes } from './developer-api-tag-types';

const APP_SUBMISSIONS_URL = '/app-submissions/api';

const appSubmissionsEndpointsFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  /**
   * Fetches all App submissions.
   * GET /app-submissions/api/submissions/metadata
   */
  getAppSubmissionsMetadata: builder.query<
    SubmissionsMetadataResponse,
    SubmissionsMetadataRequest | undefined
  >({
    query: (body) => {
      const pathParts = [`${APP_SUBMISSIONS_URL}/submissions/metadata`];
      if (body?.client_id) {
        pathParts.push(`?client_id=${body?.client_id}`);
      }
      return {
        path: pathParts.join(''),
        method: HTTP_METHOD.GET,
      };
    },
    providesTags: [DeveloperApiTagTypes.AppSubmission],
  }),

  /**
   * Create App submission
   * POST /app-submissions/api/submissions
   */
  createAppSubmission: builder.mutation<
    CreateAppSubmissionResponse,
    CreateAppSubmissionRequest
  >({
    query: (body) => {
      return {
        path: `${APP_SUBMISSIONS_URL}/submissions`,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.AppSubmission],
  }),

  /**
   * Retrieve QA submission
   * GET /app-submissions/api/qa-submissions/:id
   */
  getQaAppSubmission: builder.query<
    GetQaAppSubmissionResponse,
    GetQaAppSubmissionRequest
  >({
    query: ({ id }) => {
      return {
        method: HTTP_METHOD.GET,
        path: `${APP_SUBMISSIONS_URL}/qa-submissions/${id}`,
      };
    },
    providesTags: [DeveloperApiTagTypes.QaAppSubmission],
  }),

  /**
   * Update QA submission
   * PUT /app-submissions/api/qa-submissions/:id
   */
  updateQaAppSubmission: builder.mutation<
    UpdateQaAppSubmissionResponse,
    UpdateQaAppSubmissionRequest
  >({
    query: ({ id, ...body }) => {
      return {
        method: HTTP_METHOD.PUT,
        path: `${APP_SUBMISSIONS_URL}/qa-submissions/${id}`,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qa_submission_detail: body,
        }),
      };
    },
    invalidatesTags: [
      DeveloperApiTagTypes.QaAppSubmission,
      DeveloperApiTagTypes.AppSubmission,
    ],
  }),

  /**
   * Update QA submission status
   * PUT /app-submissions/api/qa-submissions/:id/change-status
   */
  changeStatusQaAppSubmission: builder.mutation<
    undefined,
    ChangeStatusQaAppSubmissionRequest
  >({
    query: ({ id, status }) => {
      return {
        method: HTTP_METHOD.PUT,
        path: `${APP_SUBMISSIONS_URL}/qa-submissions/${id}/change-status?status=${status}`,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
      };
    },
    invalidatesTags: [
      DeveloperApiTagTypes.QaAppSubmission,
      DeveloperApiTagTypes.AppSubmission,
    ],
  }),

  /**
   * Submit QA submission
   * PUT /app-submissions/api/qa-submissions/:id/submit
   */
  submitQaAppSubmission: builder.mutation<
    SubmitQaAppSubmissionResponse,
    SubmitQaAppSubmissionRequest
  >({
    query: ({ id, ...body }) => {
      return {
        method: HTTP_METHOD.PUT,
        path: `${APP_SUBMISSIONS_URL}/qa-submissions/${id}/submit`,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qa_submission_detail: body,
        }),
      };
    },
    invalidatesTags: [
      DeveloperApiTagTypes.QaAppSubmission,
      DeveloperApiTagTypes.AppSubmission,
    ],
  }),

  /**
   * Retrieve reviewer feedback
   * GET /app-submissions/api/qa-submissions/:id/feedback
   */
  getQaAppSubmissionFeedback: builder.query<
    GetQaAppSubmissionFeedbackResponse,
    GetQaAppSubmissionFeedbackRequest
  >({
    query: ({ id }) => {
      return {
        method: HTTP_METHOD.GET,
        path: `${APP_SUBMISSIONS_URL}/qa-submissions/${id}/feedback`,
      };
    },
    providesTags: [DeveloperApiTagTypes.ReviewerFeedback],
  }),

  /**
   * Respond to feedback
   * PUT /app-submissions/api/qa-submissions/:id/feedback/:feedback_id
   */
  updateQaAppSubmissionFeedback: builder.mutation<
    UpdateQaAppSubmissionFeedbackResponse,
    UpdateQaAppSubmissionFeedbackRequest
  >({
    query: ({ id, feedback_id, ...body }) => {
      return {
        method: HTTP_METHOD.PUT,
        path: `${APP_SUBMISSIONS_URL}/qa-submissions/${id}/feedback/${feedback_id}`,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.ReviewerFeedback],
  }),

  /**
   * Accept ToS
   * POST /app-submissions/api/partner-tos
   */
  acceptAppSubmissionPartnerTos: builder.mutation<
    AcceptAppSubmissionPartnerTosResponse,
    AcceptAppSubmissionPartnerTosRequest
  >({
    query: (body) => {
      return {
        method: HTTP_METHOD.POST,
        path: `${APP_SUBMISSIONS_URL}/partner-tos`,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.ToS],
  }),

  /**
   * Retrieve ToS acceptance data
   * GET /app-submissions/api/partner-tos
   */
  getAppSubmissionPartnerTos: builder.query<
    GetAppSubmissionPartnerTosResponse,
    undefined
  >({
    query: () => {
      return {
        method: HTTP_METHOD.GET,
        path: `${APP_SUBMISSIONS_URL}/partner-tos`,
      };
    },
    providesTags: [DeveloperApiTagTypes.ToS],
  }),
});

const appSubmissionsEndpoints = appSubmissionsEndpointsFactory(endpointBuilder);

export { appSubmissionsEndpoints, appSubmissionsEndpointsFactory };
