enum CanonicalRole {
  API_LOGS_READ = 'API_LOGS_READ',
  DEVELOPER_APPLICATION_APPLE_PAY_READ = 'DEVELOPER_APPLICATION_APPLE_PAY_READ',
  DEVELOPER_APPLICATION_APPLE_PAY_WRITE = 'DEVELOPER_APPLICATION_APPLE_PAY_WRITE',
  DEVELOPER_APPLICATION_CREDENTIALS_READ = 'DEVELOPER_APPLICATION_CREDENTIALS_READ',
  DEVELOPER_APPLICATION_CREDENTIALS_WRITE = 'DEVELOPER_APPLICATION_CREDENTIALS_WRITE',
  DEVELOPER_APPLICATION_OAUTH_READ = 'DEVELOPER_APPLICATION_OAUTH_READ',
  DEVELOPER_APPLICATION_OAUTH_WRITE = 'DEVELOPER_APPLICATION_OAUTH_WRITE',
  DEVELOPER_APPLICATION_POINT_OF_SALE_API_READ = 'DEVELOPER_APPLICATION_POINT_OF_SALE_API_READ',
  DEVELOPER_APPLICATION_POINT_OF_SALE_API_WRITE = 'DEVELOPER_APPLICATION_POINT_OF_SALE_API_WRITE',
  DEVELOPER_APPLICATION_READ = 'DEVELOPER_APPLICATION_READ',
  DEVELOPER_APPLICATION_WRITE = 'DEVELOPER_APPLICATION_WRITE',
  DEVELOPER_APPLICATION_READER_SDK_READ = 'DEVELOPER_APPLICATION_READER_SDK_READ',
  DEVELOPER_APPLICATION_READER_SDK_WRITE = 'DEVELOPER_APPLICATION_READER_SDK_WRITE',
  DEVELOPER_APPLICATION_REVIEWS_READ = 'DEVELOPER_APPLICATION_REVIEWS_READ',
  DEVELOPER_APPLICATION_REVIEWS_WRITE = 'DEVELOPER_APPLICATION_REVIEWS_WRITE',
  DEVELOPER_APPLICATION_SUBMISSIONS_READ = 'DEVELOPER_APPLICATION_SUBMISSIONS_READ',
  DEVELOPER_APPLICATION_SUBMISSIONS_WRITE = 'DEVELOPER_APPLICATION_SUBMISSIONS_WRITE',
  DEVELOPER_APPLICATION_WEBHOOKS_READ = 'DEVELOPER_APPLICATION_WEBHOOKS_READ',
  DEVELOPER_APPLICATION_WEBHOOKS_WRITE = 'DEVELOPER_APPLICATION_WEBHOOKS_WRITE',
  DEVELOPER_COLLABORATOR_PERMISSIONS_READ = 'DEVELOPER_COLLABORATOR_PERMISSIONS_READ',
  DEVELOPER_COLLABORATOR_PERMISSIONS_WRITE = 'DEVELOPER_COLLABORATOR_PERMISSIONS_WRITE',
  DEVELOPER_PROFILE_READ = 'DEVELOPER_PROFILE_READ',
  DEVELOPER_PROFILE_WRITE = 'DEVELOPER_PROFILE_WRITE',
  DEVELOPER_SANDBOX_ACCOUNT_READ = 'DEVELOPER_SANDBOX_ACCOUNT_READ',
  DEVELOPER_SANDBOX_ACCOUNT_WRITE = 'DEVELOPER_SANDBOX_ACCOUNT_WRITE',
  MERCHANT_OWNER = 'MERCHANT_OWNER',
  MERCHANT_PROFILE_READ = 'MERCHANT_PROFILE_READ',
  MERCHANT_PROFILE_WRITE = 'MERCHANT_PROFILE_WRITE',
}

export { CanonicalRole };
