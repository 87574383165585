/**
 * Returns the public site name or default.
 */
function getNextPublicNameOrDefault(defaultName = ''): string {
  return process.env.NEXT_PUBLIC_SITE_NAME || defaultName;
}

/**
 * Returns the public API Reference site name or default.
 */
function getNextPublicApiReferenceNameOrDefault(defaultName = ''): string {
  return (
    process.env.NEXT_PUBLIC_API_REFERENCE_SITE_NAME ||
    getNextPublicNameOrDefault(defaultName)
  );
}

/**
 * Returns the public docs site name or default.
 */
function getNextPublicDocsNameOrDefault(defaultName = ''): string {
  return (
    process.env.NEXT_PUBLIC_DOCS_SITE_NAME ||
    getNextPublicNameOrDefault(defaultName)
  );
}

export {
  getNextPublicNameOrDefault,
  getNextPublicApiReferenceNameOrDefault,
  getNextPublicDocsNameOrDefault,
};
